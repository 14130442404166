import { useApi } from '@tenant/composables/use-api'

export const useUploadFile = (endpoint, method = 'POST') => {
    const { execute, ...restApi } = useApi(endpoint, method)

    const executeMultipleUpload = (
        files,
        fieldName = 'files',
        configs = {}
    ) => {
        const formData = new FormData()
        for (const file of files) {
            formData.append(fieldName, file)
        }

        return execute({
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...configs,
        })
    }

    const executeSingleUpload = (
        file,
        fieldName = 'file',
        type,
        configs = {}
    ) => {
        const formData = new FormData()
        formData.append(fieldName, file)

        return execute({
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...configs,
        })
    }

    return {
        executeMultiple: executeMultipleUpload,
        executeSingle: executeSingleUpload,
        ...restApi,
    }
}
